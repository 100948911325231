import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ItemPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  const pdfTitles = [
    'Customer',
    'Customer Sort',
    'Receipt Number',
    'Date',
    'Tot Ble',
    'Tot Mts',
    'Ble Prc',
    'Mts Prc',
    'Bal Ble',
    'Bal Mts',
  ];
  const [pdfContent, setPdfContent] = useState([[]]);
  const [pdfData, setPdfData] = useState({});
  useEffect(() => {
    setPdfData({
      fromDate: '',
      toDate: to,
      tableTitles: pdfTitles,
      tableContents: pdfContent,
      documentTitle: 'Stock',
    });
  }, [pdfContent]);
  useEffect(() => {
    authCheck(state.authData);
    getCustomers();
    getSorts('');
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const postApi = () => {
    const postData = {
      data: {
        customer,
        customerSort,
        to,
      },
    };
    let validate = requireCheck(postData);

    if (!validate) {
      return false;
    }
    let cust = customer.toString();
    let sort = customerSort.toString();
    showLoader();
    axios
      .get(baseUri() + 'grey-receipt-advice/stock-report?users=' + cust + '&customersSort=' + sort + '&upto=' + to, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let totalBales = 0,
          totalMeters = 0,
          totalBalesProcessed = 0,
          totalMetersProcessed = 0,
          totalMetersRemaning = 0,
          totalBalesRemaining = 0;
        const pdfData: Array<Array<any>> = [];
        response.data.data.result = response.data.data.result.sort((a: any, b: any) => {
          a.sort_name = a.sort_name ? a.sort_name : '';
          b.sort_name = b.sort_name ? b.sort_name : '';
          return a?.name.localeCompare(b?.name) || a?.sort_name.localeCompare(b?.sort_name);
        });
        let prevName = '';
        let prevSortName = '';
        let sortTotCount = 1;
        let sortNameTotal = {
          meters: 0,
          bales: 0,
          metersProcessed: 0,
          balesProcessed: 0,
          metersRemaining: 0,
          balesRemaining: 0,
        };
        let customerTotal = {
          meters: 0,
          bales: 0,
          metersProcessed: 0,
          balesProcessed: 0,
          metersRemaining: 0,
          balesRemaining: 0,
        };

        response.data.data.result.forEach((data: any) => {
          totalBales += data.bale_count;
          totalMeters += data.total_meters;
          totalBalesProcessed += data.bales_processed;
          totalMetersProcessed += data.meters_processed;
          totalBalesRemaining += +data.Balance_Bales;
          totalMetersRemaning += data.balance_meters;
          let balance_bales = +data.Balance_Bales;
          let currName = '  ';
          let currSortName = '  ';
          if (data.sort_name.length === 0) {
            data.sort_name = 'null';
          }
          if (prevSortName === data.sort_name) {
            sortTotCount++;
            sortNameTotal.meters += data?.total_meters || 0;
            sortNameTotal.bales += data?.bale_count || 0;
            sortNameTotal.balesProcessed += data?.bales_processed || 0;
            sortNameTotal.metersProcessed += data?.meters_processed || 0;
            sortNameTotal.balesRemaining += +data?.Balance_Bales || 0;
            sortNameTotal.metersRemaining += data?.balance_meters || 0;
          } else if (prevSortName !== data.sort_name) {
            currSortName = data.sort_name;
            if (sortTotCount > 1) {
              let currPdfData = [
                '  ',
                '  ',
                '  ',
                'Sort Total',
                sortNameTotal.bales.toLocaleString('en-IN'),
                sortNameTotal.meters.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
                sortNameTotal.balesProcessed.toLocaleString('en-IN'),
                sortNameTotal.metersProcessed.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
                sortNameTotal.balesRemaining.toLocaleString('en-IN'),
                sortNameTotal.metersRemaining.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
              ];
              pdfData.push(currPdfData);
            }
            prevSortName = data.sort_name;
            sortTotCount = 1;
            sortNameTotal = {
              meters: data?.total_meters || 0,
              bales: data?.bale_count || 0,
              balesProcessed: data?.bales_processed || 0,
              metersProcessed: data?.meters_processed || 0,
              balesRemaining: +data?.Balance_Bales || 0,
              metersRemaining: data?.balance_meters || 0,
            };
          }

          if (prevName !== data.name) {
            currName = data.name;
            prevName = currName;
            if (
              !(
                customerTotal.bales === 0 &&
                customerTotal.meters === 0 &&
                customerTotal.balesProcessed === 0 &&
                customerTotal.metersProcessed === 0 &&
                customerTotal.balesRemaining === 0 &&
                customerTotal.metersRemaining === 0
              )
            )
              pdfData.push([
                '  ',
                '  ',
                '  ',
                'Customer Total',
                customerTotal.bales.toLocaleString('en-IN'),
                customerTotal.meters.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
                customerTotal.balesProcessed.toLocaleString('en-IN'),
                customerTotal.metersProcessed.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
                customerTotal.balesRemaining.toLocaleString('en-IN'),
                customerTotal.metersRemaining.toLocaleString('en-IN', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
              ]);
            customerTotal = {
              meters: data?.total_meters || 0,
              bales: data?.bale_count || 0,
              balesProcessed: data?.bales_processed || 0,
              metersProcessed: data?.meters_processed || 0,
              balesRemaining: +data?.Balance_Bales || 0,
              metersRemaining: data?.balance_meters || 0,
            };
          } else {
            customerTotal.meters += data?.total_meters || 0;
            customerTotal.bales += data?.bale_count || 0;
            customerTotal.balesProcessed += data?.bales_processed || 0;
            customerTotal.metersProcessed += data?.meters_processed || 0;
            customerTotal.balesRemaining += +data?.Balance_Bales || 0;
            customerTotal.metersRemaining += data?.balance_meters || 0;
          }

          pdfData.push([
            currName,
            currSortName,
            data?.receipt_number,
            data['DATE(gra.receipt_date)'],
            data?.bale_count.toLocaleString('en-IN'),
            data?.total_meters?.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            data?.bales_processed?.toLocaleString('en-IN'),
            data?.meters_processed?.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            balance_bales?.toLocaleString('en-IN'),
            data?.balance_meters?.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
          ]);
        });
        response.data.data.result.push({
          name: 'Total',
          sort_name: '',
          receipt_number: '',
          'DATE(gra.receipt_date)': '',
          bale_count: totalBales,
          total_meters: totalMeters,
          bales_processed: totalBalesProcessed,
          meters_processed: totalMetersProcessed,
          Balance_Bales: `${totalBalesRemaining}`,
          balance_meters: totalMetersRemaning,
        });

        if (sortTotCount > 1) {
          pdfData.push([
            '  ',
            '  ',
            '  ',
            'Sort Total',
            sortNameTotal.bales.toLocaleString('en-IN'),
            sortNameTotal.meters.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
            sortNameTotal.balesProcessed.toLocaleString('en-IN'),
            sortNameTotal.metersProcessed.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
            sortNameTotal.balesRemaining.toLocaleString('en-IN'),
            sortNameTotal.metersRemaining.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          ]);
        }

        pdfData.push([
          '  ',
          '  ',
          '  ',
          'Customer Total',
          customerTotal.bales.toLocaleString('en-IN'),
          customerTotal.meters.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          customerTotal.balesProcessed.toLocaleString('en-IN'),
          customerTotal.metersProcessed.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          customerTotal.balesRemaining.toLocaleString('en-IN'),
          customerTotal.metersRemaining.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        ]);

        pdfData.push([
          'Grand Total',
          '  ',
          '  ',
          '  ',
          totalBales.toLocaleString('en-IN'),
          totalMeters.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          totalBalesProcessed.toLocaleString('en-IN'),
          totalMetersProcessed.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          totalBalesRemaining.toLocaleString('en-IN'),
          totalMetersRemaning.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        ]);

        setItemData(response.data.data.result);
        setPdfContent(pdfData);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data.result);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
        console.log(error);
      });
  };

  const [customer, setCustomer] = useState([]);
  const [defaultCustomer, setDefaultCustomer] = useState([{ label: 'All', value: '' }]);
  const [userOptions, setUserOptions] = useState([]);
  const [customerSort, setCustomerSort] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState([{ label: 'All', value: '' }]);
  const [sortOptions, setSortOptions] = useState([]);
  const [to, setTo] = useState(dateFormat(new Date().toISOString()));

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'to':
        setTo(event.target.value);
    }
  };

  const handleCustomerChange = (event) => {
    let selected = [];
    let fliterStr = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      if (item.value) {
        selected.push(item.value);
        fliterStr.push(`filters[user][id][$in][${index}]=${item.value}`);
      }
    });
    setCustomer(selected);
    setDefaultCustomer(event);
    pullDownReset('customer');
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    setSortOptions([]);
    getSorts(fliterStr.join('&'));
  };

  const handleCustomerSortChange = (event) => {
    let selected = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected.push(item.value);
    });
    setCustomerSort(selected);
    setDefaultCustomerSort(event);
    pullDownReset('customerSort');
  };

  const clearFormData = () => {
    getSorts('');
    setTo('');
    setCustomer([]);
    setDefaultCustomer([{ label: 'All', value: '' }]);
    setCustomerSort([]);
    setDefaultCustomerSort([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = (query) => {
    setCustomerSort([]);
    showLoader();
    axios
      .get(baseUri() + `user-sorts?${query}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [{ label: 'All', value: '' }];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortName, value: item.id });
        });
        setSortOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Customer',
      selector: (row) => `${row.name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Customer Sort',
      selector: (row) => `${row.sort_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Receipt Number',
      selector: (row) => `${row.receipt_number}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Date',
      selector: (row) => `${row['DATE(gra.receipt_date)']}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Total Bales',
      selector: (row) => (row.bale_count ? row.bale_count : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Total Meters',
      selector: (row) => (row.total_meters ? row.total_meters : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Bales Processed',
      selector: (row) => (row.bales_processed ? row.bales_processed : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Meters Processed',
      selector: (row) => (row.meters_processed ? row.meters_processed : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Balance Bales',
      selector: (row) => (row.Balance_Bales ? +row.Balance_Bales : 0),
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Balance Meters',
      selector: (row) => (row.balance_meters ? row.balance_meters : 0),
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = ['name', 'receipt_number', 'total_meters', 'total_kgs', 'meters_processed'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="GRA Stock Generator" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> GRA Stock Generator</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Customer <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerChange(e)}
                    options={userOptions}
                    id="customer"
                    name="customer"
                    placeholder="Select Customer *"
                    value={defaultCustomer}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Customer Sort</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => handleCustomerSortChange(e)}
                    options={sortOptions}
                    id="customerSort"
                    name="customerSort"
                    placeholder="Select Customer Sort"
                    value={defaultCustomerSort}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    As on Date <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="date"
                      name="to"
                      placeholder="date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      required
                      className="to require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>GRA Stock</header>
            <CardBody>
              {showDownload && (
                <p>
                  <PdfGenerator documentTitle="Stock" data={pdfData} />
                  {'       '}
                  <CSVLink data={tableData}>Download Excel</CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ItemPage;
